import React from 'react'
import { kebabCase } from 'lodash'
import { Link, graphql, useStaticQuery } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/Seo'

const TagsPage = ({ location }) => {
  const data = useStaticQuery (
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        strapiBlog {
          title
          subtitle
          cover {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  transformOptions: {grayscale: true}
                )
              }
            }
          }
        }
        allStrapiArticle(limit: 1000) {
          group(field: tags___name) {
            fieldValue
            totalCount
          }
        }
        allMarkdownRemark(limit: 1000) {
          group(field: frontmatter___tags) {
            fieldValue
            totalCount
          }
          
        }
      }
    `
  )
  
  return (
    <Layout location={location} pageData={data.strapiBlog}>
      <Seo
        title="All taglabels"
        description="Alle gebruikte tags op de site"
      />

      <nav className="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li><Link to="/">Home</Link></li>
          <li className="is-active">
            <Link to="/blog">Tags</Link>
          </li>
        </ul>
      </nav>

      <div className="container content">
        <h1 className="title">Alle gebruikte labels</h1>

        <h2 className="subTitle">Posts</h2>
        <div className="tags are-medium">
          {data.allStrapiArticle.group.map(tag => (
            <Link
              to={`/tags/${kebabCase(tag.fieldValue)}/`}
              key={tag.fieldValue}
              className="tag is-link"
              >
                <span>{tag.fieldValue} ({tag.totalCount})</span>
            </Link>
          ))}
        </div>

        <h2 className="subTitle">Gearchiveerde Posts</h2>
        <div className="tags are-medium">
          {data.allMarkdownRemark.group.map(tag => (
            <Link
              to={`/tags/${kebabCase(tag.fieldValue)}/`}
              key={tag.fieldValue}
              className="tag is-link"
              >
                <span>{tag.fieldValue} ({tag.totalCount})</span>
            </Link>
          ))}
        </div>

      </div>

    </Layout>
  )
} 

export default TagsPage
